<template>
  <div class="manage-app-popups">
    <div class="container fluid">
      <h2 class="p-2 mb-3">Manage App Popups</h2>

      <Banner
        v-if="popups.length > 8"
        class="popup-warn mb-3"
        :class="{
          'bg-warn fg-white': popups.length > 10,
          'bg-blue-100 fg-grey-800': popups.length < 10
        }"
      >
        There are currently
        <span class="font-bold">{{ popups.length }}</span> popups. It is
        recommended not to exceed <span class="font-bold">10</span> popups.
      </Banner>

      <!-- Action-->
      <div class="flat card d-flex justify-content-end p-2 mb-2">
        <!-- <div class="row align-items-center">
          <label class="label mr-2">
            <i class="fas fa-sort-amount-down mr-1"></i>
            Sort Mode
          </label>
          <toggle-switch v-model="sortMode"></toggle-switch>
        </div> -->
        <fd-button class="main medium" @click="addNewPopup">
          <i class="fas fa-plus"></i>
          <span class="xs-d-none ml-1">Add New Popup</span>
        </fd-button>
      </div>

      <!-- Warn unsaved position change -->
      <!-- <Banner
        v-if="hasRepositioned && !isLoading"
        class="bg-secondary fg-white p-2"
        :dismissable="false"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <i class="fas fa-exclamation-triangle mr-2"></i>
            You have not saved after sorting the popups.
          </div>
          <div>
            <fd-button class="bordered white" @click="resetPosition"
              >Reset</fd-button
            >
            <fd-button
              class="bg-white fg-secondary ml-2"
              @click="saveNewPosition"
              >Save</fd-button
            >
          </div>
        </div>
      </Banner> -->

      <!-- ========================== Popup List ========================== -->
      <!-- Loading State -->
      <div v-if="isLoading">
        <app-popup-card v-for="n in 3" :key="n" isLoading></app-popup-card>
      </div>

      <!-- No Data -->
      <div v-else-if="popups.length < 1" class="card p-3">
        <no-data message="No popup has been created yet."></no-data>
      </div>
      <!-- Listing -->
      <div v-else>
        <fd-button
          v-if="sortMode && hasRepositioned"
          class="fixed medium round main"
          @click="saveNewPosition"
        >
          <i class="fas fa-edit mr-2"></i> Save
        </fd-button>
        <Draggable
          v-model="popupList"
          tag="ul"
          class="p-0"
          v-bind="dragOptions"
          handle=".handle"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group
            type="transition"
            :name="!dragging ? 'flip-list' : null"
          >
            <app-popup-card
              v-for="popup in popupList"
              :key="popup.id"
              :popup="popup"
              :sortable="sortMode"
              @set-is-active="setIsActive"
              @view-image="viewFullImage"
              @edit="editPopup"
              @delete="deletePopup"
            ></app-popup-card>
          </transition-group>
        </Draggable>
      </div>

      <!-- Popup Lightbox -->
      <cool-light-box
        :items="popupImage"
        :index="popupImageIndex"
        @close="popupImageIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
    </div>

    <modal v-model="popupEditor.isShown">
      <app-popup-editor
        :isEdit="popupEditor.isEdit"
        :popup="popupEditor.popupToEdit"
        @cancel="popupEditor.isShown = false"
        @submit="handlePopupFormSubmit"
      ></app-popup-editor>
    </modal>
  </div>
</template>

<script>
import { appPopup as popupAPI } from "@/api";
import Draggable from "vuedraggable";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

export default {
  components: {
    AppPopupCard: () => import("@/components/AppPopup/PopupCard"),
    AppPopupEditor: () => import("@/components/AppPopup/PopupEditor"),
    Banner: () => import("@/components/GlobalComponents/Banner"),
    Draggable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      sortMode: false,
      dragging: false,
      dragOptions: {
        animation: 200,
        group: "popup",
        disabled: false,
        ghostClass: "ghost"
      },

      popupImage: [],
      popupImageIndex: null,

      popups: [],
      initialPositions: [],

      isLoading: false,

      popupEditor: {
        isShown: false,
        isEdit: false,
        popupToEdit: {}
      }
    };
  },
  computed: {
    popupList: {
      get() {
        return sortBy(this.popups, ["position"]).map((popup, index) => {
          popup.position = index + 1;
          return popup;
        });
      },
      set(val) {
        val.forEach((popup, index) => {
          let newPosition = index + 1;
          let foundIndex = this.popups.findIndex((el) => el.id === popup.id);
          this.popups[foundIndex].position = newPosition;
        });
      }
    },
    hasRepositioned() {
      let currentPositions = this.popups.map((popup) => popup.position);

      return !isEqual(this.initialPositions, currentPositions);
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initPopups();
  },
  methods: {
    async initPopups() {
      try {
        this.isLoading = true;
        await this.getPopups();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async getPopups() {
      try {
        let data = await popupAPI.getPopups();
        this.initialPositions = data.map((popup) => popup.position);
        this.popups = this._.cloneDeep(data);
      } catch (error) {
        console.error(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to load popup list. Please try again later."
        });
        throw error;
      }
    },
    addNewPopup() {
      this.popupEditor.isEdit = false;
      this.popupEditor.isShown = true;
    },
    editPopup(popup) {
      this.popupEditor.popupToEdit = popup;
      this.popupEditor.isEdit = true;
      this.popupEditor.isShown = true;
    },
    viewFullImage(url) {
      this.popupImage = [url];
      this.popupImageIndex = 0;
    },
    handlePopupFormSubmit(payload) {
      if (this.popupEditor.isEdit) {
        this.updatePopup(payload);
      } else {
        this.createPopup(payload);
      }
    },
    async createPopup(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await popupAPI.createPopup(payload);
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Popup has been created successfully."
        });
        this.popupEditor.isShown = false;

        await this.initPopups();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create a new popup. Please try again later."
        });
      }
    },
    async updatePopup(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await popupAPI.updatePopup(this.popupEditor.popupToEdit.id, payload);
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Popup has been updated successfully."
        });
        this.popupEditor.isShown = false;

        await this.initPopups();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the popup. Please try again later."
        });
      }
    },
    async deletePopup(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Popup",
        message:
          "Are you sure you want to delete this popup? This action cannot be undone.",
        confirmText: "Delete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await popupAPI.deletePopup(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Popup has been deleted successfully."
          });
          this.$store.commit("setIsLoading", false);

          await this.initPopups();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete the popup. Please try again later."
          });
        }
      }
    },
    resetPosition() {
      this.initialPositions.forEach((position, index) => {
        this.popups[index].position = position;
      });
    },
    async saveNewPosition() {
      try {
        this.$store.commit("setIsLoading", true);
        let payload = this.popupList.map((popup) => ({
          id: popup.id,
          position: popup.position
        }));
        await popupAPI.sortPopup(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Adjusted position of popups has been saved."
        });
        this.$store.commit("setIsLoading", false);
        this.initPopups();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to save position of popups. Please try again later."
        });
      }
    },
    async setIsActive(id, isActive) {
      let action = isActive ? "activate" : "deactivate";
      let actionPT = isActive ? "activated" : "deactivated";

      try {
        this.$store.commit("setIsLoading", true);
        await popupAPI.setPopupIsActive(id, { isActive: isActive });
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: `Popup has been successfully ${actionPT}.`
        });
        this.initPopups();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Failed to ${action} the popup. Please try again later.`
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-app-popups {
  .popup-warn {
    font-size: 16px;
    font-weight: normal;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
  }
}
</style>
